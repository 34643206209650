html {
  box-sizing: border-box;
}

html,
body,
#root,
#root > div {
  height: 100%;
  background: #fafbfd;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input {
  font: inherit;
}
